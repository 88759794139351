import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCommonFacilityListAsync } from "src/api/building/building-api";
import { CommonFacilityModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getPriceRulesListAsync } from "src/api/price/price-api";
import { PriceRulesModel } from "src/api/price/price-types";
import { getProviderList } from "src/api/provider/provider-api";
import { ProviderModel } from "src/api/provider/provider-types";
import { Order, PageMeta, Sort } from "src/api/public-types";
import { BaseButton, BaseInput, BaseInputWithSearch, BaseSelect, BaseTable } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { qsParse } from "src/utils";
import priceListColumn from "./columns/priceListColumn";
import SearchBuildingPopup from "src/pages/commonPopup/SearchBuildingPopup";
import ProviderSelectModal from "../components/ProviderSelectModal";
import AppliedBasePriceModal from "../components/AppliedBasePriceModal";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  sort?: Sort;
  searchType?: string;
  keywordList?: string;
  providerName?: string;
  providerId?: string;
  buildingName?: string;
  buildingId?: string;
  buildingCommonFacilityId?: string;
  isDisplayed?: string;
  containsTypes?: string;
  partnerId?: string;
  partnerName?: string;
  priceRuleType?: string;
};

const searchTypes = [
  {
    label: "요금정책 명",
    value: "RULE_NAME",
  },
];

const ruleTypes = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "기본 요금",
    value: "PRICE_RULE_PROVIDER,PRICE_RULE_FACILITY",
  },
  {
    label: "개별 요금",
    value: "PRICE_RULE_SPECIFIC",
  },
];

/**
 * 공용공간 요금 > 목록 화면
 */
const PriceList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qsParse(location.search);

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.providerName) {
      _queryParams.providerName = "";
    }
    if (!_queryParams?.searchType) {
      _queryParams.searchType = "RULE_NAME";
    }
    if (!_queryParams?.buildingName) {
      _queryParams.buildingName = "";
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    // qs.parse에 decoder때문에 검색값 입력 검색시 한번더 decoding
    if (_queryParams.keywordList) {
      _queryParams.keywordList = decodeURIComponent(_queryParams.keywordList);
    }

    return _queryParams;
  }, [location.search]);

  const [params, setParams] = useState<QueryParams>({ ...queryParams });
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [priceList, setPriceList] = useState<PriceRulesModel[]>();
  const [isProviderModalOpen, setIsProviderModalOpen] = useState({ isOpen: false });
  const [isBuildingModalOpen, setIsBuildingModalOpen] = useState({ isOpen: false });
  const [isAppliedBasePriceModalOpen, setIsAppliedBasePriceModalOpen] = useState({ isOpen: false });
  const { executeAsync: getPriceList } = useApiOperation(getPriceRulesListAsync);
  const { executeAsync: getProvider } = useApiOperation(getProviderList);
  const { executeAsync: getCommonFacilityList } = useApiOperation(getCommonFacilityListAsync);
  const { isAuthority } = usePartnerAuthority();
  // 쿼리파람과 함께 navigate
  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  const onAddedProviderSelectModal = (
    provider: {
      providerId: string;
      providerName: string;
    }[],
  ) => {
    if (!provider) return;
    setIsProviderModalOpen({ isOpen: false });
    navigateWithQueryParams({ ...params, providerName: provider[0].providerName, providerId: String(provider[0].providerId) });
  };

  const onAddedBuildingSelectModal = (building: any) => {
    if (!building) return;
    setIsBuildingModalOpen({ isOpen: false });
    navigateWithQueryParams({ ...params, buildingName: building.buildingName, buildingId: String(building.id) });
  };

  useEffect(() => {
    setParams({ ...queryParams }); // input 초기화
    const fetchApi = async (priceParams: QueryParams) => {
      // 목록 조회
      let { providerName, buildingName, buildingCommonFacilityId, ...payload } = priceParams;
      const response = await getPriceList(payload);
      if (response.status >= 200 && response.status <= 299) {
        const priceData = response.data.data.content;
        let providerData: ProviderModel[] = [];
        let facilityData: CommonFacilityModel[] = [];

        // provider목록 조회해서 providerName 매핑
        const removeDuplicateProvider = new Set(priceData.map((price) => price.providerId));
        const removeDuplicateBuildingFacility = new Set(priceData.map((price) => price.buildingCommonFacilityId));

        const providerListString = [...removeDuplicateProvider].filter((item) => item !== null).join(",");
        const buildingFacilityListString = [...removeDuplicateBuildingFacility].filter((item) => item !== null).join(",");

        const providerResponse = await getProvider({ providerId: providerListString });
        if (providerResponse.status >= 200 && providerResponse.status <= 299) {
          providerData = providerResponse?.data?.data?.content;
        }
        // commonFacility 목록 조회해서 buildingName, facilityName 매핑
        const buildingFacilityResponse = await getCommonFacilityList({
          searchType: "ID",
          keywordList: buildingFacilityListString,
          commonFacilityTypeList: "",
        });
        if (buildingFacilityResponse.status >= 200 && buildingFacilityResponse.status <= 299) {
          facilityData = buildingFacilityResponse?.data?.data?.content;
        }

        const forColumnPriceData = priceData.map((price) => {
          const findProviderName = providerData.find((provider) => provider.providerId === price.providerId)?.providerName;
          const findBuildingName = facilityData.find((facility) => facility.id === price.buildingCommonFacilityId)?.buildingName;
          const findFacilityName = facilityData.find((facility) => facility.id === price.buildingCommonFacilityId)?.facilityName;
          return { ...price, providerName: findProviderName || "", buildingName: findBuildingName || "", facilityName: findFacilityName || "" };
        });

        setPriceList(forColumnPriceData);
        setPageMeta(response.data.meta.pageMeta);
      }
    };

    fetchApi(queryParams);
  }, [queryParams, getPriceList, getProvider, getCommonFacilityList]);

  return (
    <div className="">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            {/* section 태그가 지정검색, 조건검색, 영역이고 반드시 필요함 */}
            <section>
              <div className="left-area__index">
                <span>지정검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax140">
                  <BaseInput
                    type="text"
                    value={params?.providerName || ""}
                    placeholder="프로바이더"
                    readonly={true}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setIsProviderModalOpen({ isOpen: true });
                    }}
                    onClearClick={() => {
                      let { providerName, providerId, ...rest } = params;
                      navigateWithQueryParams({ ...rest });
                    }}
                  />
                  {isProviderModalOpen.isOpen && (
                    <ProviderSelectModal
                      onCanceled={() => setIsProviderModalOpen({ isOpen: false })}
                      onAdded={onAddedProviderSelectModal}
                      defaultChecked={params.providerName ? [{ providerId: String(params.providerId), providerName: params.providerName }] : []}
                    />
                  )}
                </div>
                <div className="minmax140">
                  <BaseInput
                    type="text"
                    value={params?.buildingName || ""}
                    placeholder="건물명"
                    readonly={true}
                    onClick={(e: any) => {
                      e.preventDefault();
                      setIsBuildingModalOpen({ isOpen: true });
                    }}
                    onClearClick={() => {
                      let { buildingName, buildingId, ...rest } = params;
                      navigateWithQueryParams({ ...rest });
                    }}
                  />
                  {isBuildingModalOpen.isOpen && (
                    <SearchBuildingPopup
                      buildingName={""}
                      isOpen={true}
                      onClick={(building: any) => onAddedBuildingSelectModal(building)}
                      onClose={() => setIsBuildingModalOpen({ isOpen: false })}
                    />
                  )}
                </div>
              </div>
            </section>
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <BaseSelect
                  placeholder="요금 유형"
                  className="minmax140"
                  stateOptions={ruleTypes}
                  value={params?.priceRuleType}
                  setStateValue={(value: string) => {
                    console.log(value);
                    // setParams({ ...params, priceRuleType: value });
                    navigateWithQueryParams({ ...params, priceRuleType: value });
                  }}
                />
                <div>
                  <BaseInputWithSearch
                    type="text"
                    selectValue={"RULE_NAME"}
                    inputValue={params?.keywordList || ""}
                    stateOptions={searchTypes}
                    isSelectDisabled={true}
                    setStateValue={(searchType: string) => {
                      setParams({ ...params, searchType });
                    }}
                    onClearClick={() => navigateWithQueryParams({ ...params, searchType: "RULE_NAME", keywordList: "" })}
                    onChange={(keywordList: string) => setParams({ ...params, keywordList })}
                    onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                    onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="right-area">
            <div className="flex-center">
              {/* <BaseButton
                title="현재 적용된 기준요금"
                className="mr10 color-white"
                onClick={() => {
                  setIsAppliedBasePriceModalOpen({ isOpen: true });
                }}
              /> */}
              {isAuthority("BASIC", "w") && (
                <BaseButton
                  title="+ 요금 등록"
                  onClick={() => {
                    navigate(PagePath.price.form);
                  }}
                />
              )}
            </div>
          </div>
          {isAppliedBasePriceModalOpen.isOpen && (
            <AppliedBasePriceModal
              isOpen={true}
              onClose={() => {
                setIsAppliedBasePriceModalOpen({ isOpen: false });
              }}
            />
          )}
        </div>

        {priceList && (
          <BaseTable
            data={priceList}
            columns={priceListColumn}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page }, "pagination");
            }}
            totalElements={Number(pageMeta?.totalElements) || 0}
            orders={queryParams?.sort?.orders}
            disabledSortHeaders={["providerName", "buildingName", "facilityName", "priceRuleType2"]}
            sizeOption={(number: number) => {
              setParams({ ...params, size: number });
              navigateWithQueryParams({ ...params, page: 0, size: number }, "search");
            }}
            currentSize={Number(queryParams.size) || 20}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ ...params, sort: { orders } });
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
export default PriceList;
