/* eslint-disable array-callback-return */

import { ProductModel } from "src/api/product/product-types";
import { BaseToggle } from "src/components";
import GoToListButton from "src/components/GoToListButton";

import useGetProduct from "src/hooks/product/useGetProduct";
import useUpdateProduct from "src/hooks/product/useUpdateProduct";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import ParkingSpace from "./components/ParkingSpace";
import SpaceContents from "./components/SpaceContents";

import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

export interface resData {
  success: Boolean;
  data?: any;
  error?: string;
}

interface IProps {
  product: ProductModel;
  productId: string;
}

const CommonSpace = ({ productId }: IProps) => {
  const { isAuthority, fetchAuthorityReadonly } = usePartnerAuthority();

  const { data: product } = useGetProduct({ productId: productId });
  const { mutate } = useUpdateProduct();

  if (!product) {
    return null;
  }

  return (
    <>
      <div className=" contract-reservation-page contents-container__scroll">
        <div className="contents-container__wrap pa0">
          <div className="">
            {/* 주차 */}
            <article className="reservation-wrap meetingroom">
              <div className="contents-container__1070">
                <section className="reservation-wrap__title">
                  <BaseSectionTitle title={"주차"} className="pb0 w178" />
                  <BaseToggle
                    checked={product.isParkingLot}
                    name="isParkingLot_use"
                    onChange={async (value: boolean) => {
                      if (!isAuthority("PRODUCT", "w")) {
                        return fetchAuthorityReadonly(true);
                      }

                      mutate({ id: product.id, isParkingLot: value });
                    }}
                  />
                </section>
                {product.isParkingLot && <ParkingSpace spaceType="MEETING_ROOM" product={product} isEditable={isAuthority("PRODUCT", "w")} />}
              </div>
            </article>
            {/* 회의실 */}
            <article className="reservation-wrap meetingroom">
              <div className="contents-container__1070">
                <section className="reservation-wrap__title">
                  <BaseSectionTitle title={"회의실"} className="pb0 w178" />
                  <BaseToggle
                    checked={product.isMeetingRoom}
                    name="meeting_use"
                    onChange={async (value: boolean) => {
                      if (!isAuthority("PRODUCT", "w")) {
                        return fetchAuthorityReadonly(true);
                      }

                      mutate({ id: product.id, isMeetingRoom: value });
                    }}
                  />
                </section>
                {product.isMeetingRoom && (
                  <SpaceContents spaceType="MEETING_ROOM" product={product} serviceId={String(product.id)} serviceType="SERVICE_PRODUCT" />
                )}
              </div>
            </article>
            {/* 좌석 */}
            <article className="reservation-wrap meetingroom">
              <div className="contents-container__1070">
                <section className="reservation-wrap__title">
                  <BaseSectionTitle title={"좌석"} className="pb0 w178" />
                  <BaseToggle
                    checked={product.isDesk}
                    name="desk_use"
                    onChange={async (value: boolean) => {
                      if (!isAuthority("PRODUCT", "w")) {
                        return fetchAuthorityReadonly(true);
                      }

                      mutate({ id: product.id, isDesk: value });
                    }}
                  />
                </section>
                {product.isDesk && <SpaceContents spaceType="DESK" product={product} serviceId={String(product.id)} serviceType="SERVICE_PRODUCT" />}
              </div>
            </article>
            {/* 편의시설 */}
            <article className="reservation-wrap meetingroom">
              <div className="contents-container__1070">
                <section className="reservation-wrap__title">
                  <BaseSectionTitle title={"편의시설"} className="pb0 w178" />
                  <BaseToggle
                    checked={product.isRefreshRoom}
                    name="refresh_use"
                    onChange={async (value: boolean) => {
                      if (!isAuthority("PRODUCT", "w")) {
                        return fetchAuthorityReadonly(true);
                      }
                      mutate({ id: product.id, isRefreshRoom: value });
                    }}
                  />
                </section>
                {product.isRefreshRoom && (
                  <SpaceContents spaceType="REFRESH_ROOM" product={product} serviceId={String(product.id)} serviceType="SERVICE_PRODUCT" />
                )}
              </div>
            </article>
          </div>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};
export default CommonSpace;
