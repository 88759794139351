/* eslint-disable array-callback-return */
import { DetailTotalData } from "src/api/contract/contract-types";
import { BaseToggle } from "src/components";
import GoToListButton from "src/components/GoToListButton";

import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

import useEditContractOperation from "src/hooks/contract/useEditContractOperation";
import useGetContractOperation from "src/hooks/contract/useGetContractOperation";
import ContractSpaceContents from "./components/ContractSpaceContents";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
interface ContractBasicProp {
  detailData: DetailTotalData; //상품 상세 조회 정보
}
/**
 * 공용공간
 * 아래 문서 참고 1.17v
 * https://pnpt.atlassian.net/wiki/spaces/dsplan/pages/569016392/Ctrl.room+-
 */

const ContractReservation = ({ detailData }: ContractBasicProp) => {
  const { fetchAuthorityReadonly, isAuthority } = usePartnerAuthority();
  // 공용공간 운영정보 상세
  const { data: contractOperationDetail } = useGetContractOperation({
    contractId: detailData.contract?.contractId ? +detailData.contract?.contractId : undefined,
  });
  // 공용공간 운영정보 수정
  const { mutate: editContractOperation } = useEditContractOperation();

  return (
    <>
      <div className=" contract-reservation-page contents-container__scroll">
        <div className="contents-container__wrap pa0">
          <div className="">
            {/* 회의실 예약제공 */}
            <article className="reservation-wrap contents-container__wrap-article full-width">
              <div className="contents-container__1070">
                <div className="flex-row">
                  <section className="reservation-wrap__title">
                    <BaseSectionTitle title={"회의실 예약 제공"} className="pb0 w178" />
                    <BaseToggle
                      checked={contractOperationDetail?.productManage?.isUseMeetingRoom}
                      name="meeting_use"
                      onChange={async (value: boolean) => {
                        if (isAuthority("CONTRACT", "w")) {
                          editContractOperation({
                            contractId: String(detailData.contract?.contractId),
                            productManage: {
                              isUseMeetingRoom: value,
                            },
                          });
                        } else {
                          fetchAuthorityReadonly(true);
                        }
                      }}
                    />
                  </section>
                  {contractOperationDetail?.productManage?.isUseMeetingRoom && (
                    <ContractSpaceContents
                      contractOperationDetail={contractOperationDetail}
                      contractManageId={String(detailData.contract?.contractManageId)}
                      spaceType={"MEETING_ROOM"}
                      detailData={detailData}
                    ></ContractSpaceContents>
                  )}
                </div>
              </div>
            </article>
            {/* 좌석 예약 제공 */}
            <article className="reservation-wrap contents-container__wrap-article full-width">
              <div className="contents-container__1070">
                <div className="flex-row">
                  <section className="reservation-wrap__title">
                    <BaseSectionTitle title={"좌석 예약 제공"} className="pb0 w178" />
                    <BaseToggle
                      checked={contractOperationDetail?.productManage?.isUseDesk}
                      name="desk_use"
                      onChange={async (value: boolean) => {
                        if (isAuthority("CONTRACT", "w")) {
                          editContractOperation({
                            contractId: String(detailData.contract?.contractId),
                            productManage: {
                              isUseDesk: value,
                            },
                          });
                        } else {
                          fetchAuthorityReadonly(true);
                        }
                      }}
                    />
                  </section>
                  {contractOperationDetail?.productManage?.isUseDesk && (
                    <ContractSpaceContents
                      contractOperationDetail={contractOperationDetail}
                      spaceType={"DESK"}
                      contractManageId={String(detailData.contract?.contractManageId)}
                      detailData={detailData}
                    ></ContractSpaceContents>
                  )}
                </div>
              </div>
            </article>
            {/* 편의시설 예약제공 */}
            <article className="reservation-wrap contents-container__wrap-article full-width">
              <div className="contents-container__1070">
                <div className="flex-row">
                  <section className="reservation-wrap__title">
                    <BaseSectionTitle title={"편의시설 예약 제공"} className="pb0 w178" />
                    <BaseToggle
                      checked={contractOperationDetail?.productManage?.isUseRefreshRoom}
                      name="refresh_use"
                      onChange={async (value: boolean) => {
                        if (isAuthority("CONTRACT", "w")) {
                          editContractOperation({
                            contractId: String(detailData.contract?.contractId),
                            productManage: {
                              isUseRefreshRoom: value,
                            },
                          });
                        } else {
                          fetchAuthorityReadonly(true);
                        }
                      }}
                    />
                  </section>
                  {contractOperationDetail?.productManage?.isUseRefreshRoom && (
                    <ContractSpaceContents
                      contractOperationDetail={contractOperationDetail}
                      spaceType={"REFRESH_ROOM"}
                      contractManageId={String(detailData.contract?.contractManageId)}
                      detailData={detailData}
                    ></ContractSpaceContents>
                  )}
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};
export default ContractReservation;
