import { CostType, ProductModel } from "src/api/product/product-types";
import { BaseButton } from "src/components";

import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";

import { ProductCostTypeLabels } from "../../product-types";

import GoToListButton from "src/components/GoToListButton";
import { isString, numberToStringWithComma } from "src/utils";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import { useEffect, useState } from "react";
import { useApiOperation } from "src/api/hooks";
import { getProviderList } from "src/api/provider/provider-api";
import { getProviderName } from "../../forms/basicInfoForm/libs";
import Link from "src/components/PartnerLink";

interface IProps {
  product: ProductModel;
  productId: string;
}

/* 
  공간상품 등록 > 등록 or 수정 > 기본 정보
 */
const ChargeDetail = ({ product: productDetail, productId }: IProps) => {
  const navigate = useNavigate();
  const { isAuthority } = usePartnerAuthority();

  return (
    <>
      <div className="contents-container__scroll">
        {productDetail?.productType !== "TIME_COURT" && (
          <div className="contents-container__wrap">
            {/* 보증금 */}
            <div className="contents-container__wrap-contents">
              <div className="contents-container__sub-title">
                <div className="">
                  <BaseSectionTitle title={"요금 관리"} className="pb10" />
                  <ul className="base-list">
                    <li>부가세 제외 금액을 입력해 주세요.</li>
                  </ul>
                </div>
              </div>

              <section className="contents-container__grid contents-container__1200">
                <div className="contents-container__grid-index">
                  <p>보증금</p>
                </div>
                <div className="contents-container__grid-contents">
                  <table className="inner-table">
                    <thead>
                      <tr>
                        <th>
                          <div className="">보증금 (원)</div>
                        </th>
                        <th>
                          <div className="">계약금 (원)</div>
                        </th>
                        <th>
                          <div className="minmax420">잔금/일자 (원)</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="minmax220 flex-center-center no-wrap">
                            <span className="font14">{numberToStringWithComma(Number(productDetail.deposit)) ?? "-"}</span>
                          </div>
                        </td>
                        <td>
                          <div className="minmax220 flex-center-center no-wrap">
                            <span className="font14">{numberToStringWithComma(Number(productDetail.earnest)) ?? "-"}</span>
                          </div>
                        </td>
                        <td>
                          <div className="flex-center-center no-wrap">
                            {productDetail.deposit && productDetail.earnest ? (
                              <span className="font14">{numberToStringWithComma(+productDetail.deposit - +productDetail.earnest) ?? "-"}</span>
                            ) : (
                              "-"
                            )}

                            <span className="mx10">/ 계약 후</span>
                            <div className="minmax20">
                              <span className="font14">{productDetail.balanceFullPaymentDate ?? "-"}</span>
                            </div>
                            <span className="mx10">일 이내</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            {/* 이용료  */}
            <div className="contents-container__wrap-contents">
              <section className="contents-container__grid contents-container__1200">
                <div className="contents-container__grid-index">
                  <p>이용료 납부유형</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start pb18 pt7">
                    <p>
                      {productDetail.costType ? productDetail.costType !== "COST_UNRECOGNIZED" && ProductCostTypeLabels[productDetail.costType] : "-"}
                    </p>
                  </div>

                  <table className="inner-table">
                    <thead>
                      <tr>
                        {productDetail.costType === "COST_BILLING_MONTHLY" && (
                          <th>
                            <div>기준일</div>
                          </th>
                        )}
                        <th>
                          <div>원가(원)</div>
                        </th>
                        <th>
                          <div>판매가(원)</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {productDetail.costType === "COST_BILLING_MONTHLY" && (
                          <td>
                            <div className="minmax220 flex-center-center no-wrap">
                              <span className="ml10 mr16 text-center">1개월</span>
                            </div>
                          </td>
                        )}

                        <td>
                          <div className="minmax220 flex-center-center no-wrap">
                            <span className="font14">
                              {productDetail.productionCost === -1 ? 0 : numberToStringWithComma(Number(productDetail.productionCost)) ?? "-"}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="minmax220 flex-center-center no-wrap">
                            <span className="font14">{numberToStringWithComma(Number(productDetail.rentalCostList?.[0]?.value1)) ?? "-"}</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        )}

        {/* 결제 관리 */}
        <div className="contents-container__wrap-article">
          <BaseSectionTitle title={"결제 관리"} className="pb10" />

          {/* VOC 링크 */}
          <section className="contents-container__grid">
            {/* 결제 취소 안내 */}
            <div className="contents-container__grid-index">
              <p>결제 및 취소 안내</p>
            </div>
            <div className="contents-container__grid-contents">
              <p className="pre-formatted">{productDetail.refundInfo || "-"}</p>
            </div>
          </section>
        </div>
      </div>
      {/* 버튼영역 */}

      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="right-area d-flex">
          {isAuthority("PRODUCT", "w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.product.form}?id=${productId}&tab=charge`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ChargeDetail;
