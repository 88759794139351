import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BuildingModel } from "src/api/building/building-types";
import { ProductModel } from "src/api/product/product-types";
import { BaseButton, BaseSelect } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";

import { useMutation, useQuery } from "@tanstack/react-query";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { getBuildingsAsync } from "src/api/building/building-api";
import { getManagementBuildingsAsync } from "src/api/contract/contract-api";
import { DetailTotalData } from "src/api/contract/contract-types";
import { getGuideMappingListAsync, postGuideMappingAsync } from "src/api/guide/guide-api";
import { ServiceUserGuide } from "src/api/guide/guide-types";
import { useApiOperation } from "src/api/hooks";
import { UnionCmdType } from "src/api/public-types";
import { BaseTooltip } from "src/components";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import SelectGuidePopup from "src/components/selectPopup/SelectGuidePopup";
import { useQueryParams } from "src/hooks/useQueryParams";
import { PagePath } from "src/pages/product/details";
import { useModal } from "src/recoil/modalState/hook";
import { useToast } from "src/recoil/toast/hook";
import { axiosInstance } from "src/utils";
import { guideMappingListCol } from "./Columns";
import GuideBaseTable from "./GuideBaseTable";
import SortSettingsModal from "./SortSettingsModal";
import useGetContractOperation from "src/hooks/contract/useGetContractOperation";
import useEditContractOperation from "src/hooks/contract/useEditContractOperation";
import VocUrlModal from "./VocUrlModal";

interface IProps {
  product?: ProductModel;
  serviceType: UnionServiceType;
  serviceId: string;
  detailData?: DetailTotalData;
}

// NEW 공간상품 이용안내
const GuideTab = ({ product, serviceType, serviceId, detailData }: IProps) => {
  const navigate = useNavigate();
  const { setAbstractModalZ1 } = useModal();
  const { openToast } = useToast();
  const params = useParams();
  const { queryParams } = useQueryParams();
  // path variable 에서 productId 추출
  const productId: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const taapSpaceGuideUrl =
    serviceType === "SERVICE_PRODUCT"
      ? `${process.env.REACT_APP_COURT_BASSEURL}/front/court/product/${productId}/public/guides`
      : `${process.env.REACT_APP_COURT_BASSEURL}/front/court/contractManage/${serviceId}/public/guides`;

  // 건물 대표 id
  const [isBuildingPrimaryId, setIsBuildingPrimaryId] = useState("");
  // 건물 옵션
  const [buildingOptions, setBuildingOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >();

  // 계약건물 운영정보 조회
  const { executeAsync: getManagementBuildings } = useApiOperation(getManagementBuildingsAsync);
  // 건물 목록 api
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);

  //이용안내 맵핑 목록 호출
  const { data: guideMappingList, refetch } = useQuery({
    queryKey: ["getGuideMappingListAsync", isBuildingPrimaryId, serviceId, serviceType],
    queryFn: () =>
      getGuideMappingListAsync(axiosInstance, {
        serviceId: serviceId,
        serviceType: serviceType,
        buildingId: isBuildingPrimaryId,
      }).then((res) => res?.data?.data?.content),
    enabled: !!isBuildingPrimaryId,
  });

  //이용안내 맵핑 목록 등록/수정/삭제
  const { mutate: postGuideMapping } = useMutation({
    mutationFn: async (serviceUserGuideList: ServiceUserGuide[]) => await postGuideMappingAsync(axiosInstance, serviceUserGuideList),
    onSuccess: () => {
      refetch();
    },
  });

  // 공용공간 운영정보 상세
  const { data: contractOperationDetail } = useGetContractOperation({
    contractId: detailData?.contract?.contractId ? +detailData.contract?.contractId : undefined,
  });

  // 상품 이용안내 탭에서 실행
  useEffect(() => {
    // 건물 리스트 select setState
    const guideBuildingPrimaryId = (buildings: Array<BuildingModel>) => {
      // 대표인 건물 찾기 (건물 내 층/호실에 isPrimary 값이 있다)
      let isPrimaryId = "";
      buildings?.forEach((building: BuildingModel) => {
        const floors = building.buildingFloorList; // 건물 층 리스트
        floors?.forEach((floor) => {
          const rooms = floor.buildingRoomList; // 건물 층 안에 호실 리스트
          const buildingId = building.id;

          // isPrimary 여부 확인
          const isPrimary = rooms?.some((room) => room.isPrimary === true);
          if (isPrimary) {
            setIsBuildingPrimaryId(String(buildingId));
            isPrimaryId = String(buildingId);
            return isPrimaryId;
          } else {
            // isPrimary 가 없으면 첫번째 빌딩 노출
            const firstBuildingId = buildings[0].id;
            setIsBuildingPrimaryId(String(firstBuildingId));
            isPrimaryId = String(firstBuildingId);
            return isPrimaryId;
          }
        });
      });
      // url에 builidngId 가 있으면 setValue - form 영역 진입시 선택한 건물 유지 필요
      if (queryParams.buildingId) {
        setIsBuildingPrimaryId(String(queryParams.buildingId));
      }
    };

    // 상품 이용안내 탭에서 buildingList가 있을 때
    if (product?.buildingList) {
      // 건물 대표 id 설정
      guideBuildingPrimaryId(product.buildingList);
      // 건물 옵션 설정
      setBuildingOptions(
        product?.buildingList?.map((building: BuildingModel) => {
          return {
            value: String(building?.id),
            label: String(building?.buildingName),
          };
        }),
      );
    }
  }, [product?.buildingList, queryParams.buildingId]);

  // 신청계약 이용안내에서 실행
  useEffect(() => {
    if (detailData?.contract?.contractManageId) {
      //신청계약 건물 운영 정보 조회
      getManagementBuildings({ contractManageId: detailData?.contract?.contractManageId! }).then((data) => {
        // 대표건물 설정
        data?.data?.data?.buildingManageList?.forEach((building) => {
          if (building.isPrimary) {
            setIsBuildingPrimaryId(String(building.buildingId));
          }
        });
        // 건물 id로 건물API 조회 후 건물명과 매칭시켜 건물 샐랙트 박스 옵션 생성
        getBuildings({ page: 0, size: 999, id: data?.data?.data?.buildingManageList.map((data) => String(data.buildingId)).join(",") }).then(
          (res) => {
            const createdBuildsOption = res?.data?.data?.content?.map((building) => ({
              value: String(building?.id),
              label: String(building?.buildingName),
            }));

            setBuildingOptions(createdBuildsOption);
          },
        );
      });
    }
  }, [detailData?.contract?.contractManageId, getBuildings, getManagementBuildings]);

  useEffect(() => {
    //url 접속시 대표건물 아이디 저장
    if (queryParams?.buildingId) {
      setIsBuildingPrimaryId(String(queryParams?.buildingId));
    }
  }, [queryParams?.buildingId]);

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-article">
            <BaseSectionTitle title={"이용안내"} />
            <article className="contents-container__1200">
              <section className="contents-container__grid contents-container__1200">
                <div className="contents-container__grid-index">
                  <p>URL</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <div className="font14">
                      <a target={"_blank"} className="text-underline" href={taapSpaceGuideUrl} rel="noreferrer">
                        {taapSpaceGuideUrl}
                        <span className="ic-target-blank"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              {serviceType === "SERVICE_PRODUCT" && (
                <section className="contents-container__grid contents-container__1200">
                  <div className="contents-container__grid-index flex-start">
                    <p className="mr4">문의채널</p>
                    <BaseTooltip touchIcon={"QUESTION"} className="mt1" size={16} tooltip="전시정보 탭에서 수정할수 있습니다." />
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <div className="font14">
                        <a className="text-underline" target={"_blank"} href={product?.vocUrl} rel="noreferrer">
                          {product?.vocUrl ?? "-"}
                          {product?.vocUrl && <span className="ic-target-blank"></span>}
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {serviceType === "SERVICE_CONTRACT_MANAGE" && (
                <section className="contents-container__grid contents-container__1200">
                  <div className="contents-container__grid-index flex-start">
                    <p className="mr4">문의채널</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-between">
                      <div className="font14">
                        <a className="text-underline" target={"_blank"} href={contractOperationDetail?.productManage?.vocUrl} rel="noreferrer">
                          {contractOperationDetail?.productManage?.vocUrl ?? "-"}
                          {contractOperationDetail?.productManage?.vocUrl && <span className="ic-target-blank"></span>}
                        </a>
                      </div>
                      <BaseButton
                        title={"설정"}
                        className="color-white"
                        onClick={() =>
                          setAbstractModalZ1({
                            isOpen: true,
                            size: "medium",
                            children: (
                              <VocUrlModal
                                vocUrl={contractOperationDetail?.productManage?.vocUrl ?? ""}
                                contractId={String(detailData?.contract?.contractId)}
                              ></VocUrlModal>
                            ),
                          })
                        }
                      />
                    </div>
                  </div>
                </section>
              )}

              {buildingOptions?.length === 0 ? (
                <section className="contents-container__grid contents-container__1200 mb30">
                  <div className="contents-container__grid-index">
                    <p>건물</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <div className="minmax140">
                        <p>데이터가 없습니다.</p>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <>
                  <section className="contents-container__grid contents-container__1200 mb30 ">
                    <div className="contents-container__grid-index">
                      <p>건물</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className="flex-row flex-center-start">
                        <div className="minmax140">
                          <BaseSelect
                            className="minmax200"
                            isDisabled={buildingOptions?.length === 1}
                            value={isBuildingPrimaryId}
                            stateOptions={buildingOptions || []}
                            setStateValue={(value: string) => {
                              const productPath = `${PagePath.product.form}?id=${productId}&tab=guide&buildingId=${value}
                              `;

                              const contractPath = `${PagePath.contract.detail.replace(":id", params?.id!)}?tab=guide&buildingId=${value}
                              `;

                              const guidePathWithBuildingId = serviceType === "SERVICE_PRODUCT" ? productPath : contractPath;
                              navigate(guidePathWithBuildingId);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    {/* 이용안내 추가 버튼 */}
                    <div className="flex-center-end mt20">
                      <BaseButton
                        title={"정렬 순서 변경"}
                        className="color-white"
                        onClick={() => {
                          setAbstractModalZ1({
                            isOpen: true,
                            size: "xlarge",
                            children: (
                              <SortSettingsModal
                                serviceId={serviceId}
                                serviceType={serviceType}
                                serviceUserGuideList={guideMappingList ?? []}
                              ></SortSettingsModal>
                            ),
                          });
                        }}
                      />
                      <BaseButton
                        title={"+ 항목 추가"}
                        className="ml10"
                        onClick={() => {
                          setAbstractModalZ1({
                            isOpen: true,
                            size: "xlarge",
                            children: (
                              <SelectGuidePopup
                                defaultDisabledList={guideMappingList?.map((data) => data.userGuide?.id!)}
                                providerId={
                                  serviceType === "SERVICE_PRODUCT" ? product?.providerId : detailData?.contractManage?.providerId?.toString()
                                }
                                onClick={(data) => {
                                  const GuideMappingDataList = data.map((id) => ({
                                    cmdType: "C" as UnionCmdType,
                                    userGuideId: id,
                                    serviceId: serviceId,
                                    serviceType: serviceType,
                                    buildingId: isBuildingPrimaryId,
                                    orderNums: 0,
                                    isDisplayed: true,
                                  }));
                                  postGuideMapping(GuideMappingDataList, {
                                    onSuccess: () => {
                                      setAbstractModalZ1({ isOpen: false });
                                      openToast({ content: "항목이 추가되었습니다.", type: "SUCCESS" });
                                    },
                                  });
                                }}
                                onClose={() => setAbstractModalZ1({ isOpen: false })}
                              />
                            ),
                          });
                        }}
                      />
                    </div>
                    <GuideBaseTable
                      data={guideMappingList}
                      columns={guideMappingListCol}
                      serviceId={serviceId}
                      serviceType={serviceType}
                    ></GuideBaseTable>
                  </section>
                </>
              )}
            </article>
          </div>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};

export default GuideTab;
