import { ProductModel } from "src/api/product/product-types";

import { BuildingModel } from "src/api/building/building-types";
import { BaseButton } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "..";
import { ProductTypes } from "../../product-types";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";

interface IProps {
  product: ProductModel;
  productId: string;
}
/* 
  공간상품 등록 > 등록 or 수정 > 상품정보
 */
const ProductInfoForm = ({ product: productDetail, productId }: IProps) => {
  const { isAuthority } = usePartnerAuthority();

  const navigate = useNavigate();

  const findPrimaryBuilding = (productDetail: ProductModel) => {
    let _findBuilding = undefined;

    productDetail.buildingList?.map((building) =>
      building.buildingFloorList?.map((buildingFloor) =>
        buildingFloor.buildingRoomList?.map((buildingRoom) => {
          if (buildingRoom.isPrimary === true) {
            _findBuilding = building;
          }
          return undefined;
        }),
      ),
    );

    return _findBuilding as unknown as BuildingModel | undefined;
  };

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-article ">
            <BaseSectionTitle title={"상세정보"} />
            <div className="flex-start">
              {/* 상품명 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">상품명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <span className="font14">{productDetail.productName ?? "-"}</span>
                  </div>
                </div>
              </section>

              {/* 한줄소개 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">한줄 소개</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <div className="minmax400">
                      <span className="font14">{productDetail.introduce ?? "-"}</span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* 상품 소개 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">상품 소개</p>
              </div>
              <div className="contents-container__grid-contents">
                <p className="font14 pre-formatted">{productDetail.description ?? "-"}</p>
              </div>
            </section>
            {productDetail?.productType === ProductTypes.TIME_COURT ? null : (
              <div className="flex-center">
                {/* 계약기간 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">계약 가능 기간</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      {productDetail.minLeasePeriod && productDetail.maxLeasePeriod ? (
                        <span className="font14">{`최소 ${productDetail.minLeasePeriod ?? "-"}개월~최대 ${
                          productDetail.maxLeasePeriod ?? "-"
                        }`}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </div>
                  </div>
                </section>

                {/* 입주 가능일 */}
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="">입주 가능일</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="flex-row flex-center-start">
                      <div className="minmax400">
                        <span className="font14">{productDetail.isRightAwayMoveIn ? "즉시" : productDetail.moveInAvailDate ?? "-"}</span>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <div>
                  <p className="">위치안내</p>
                </div>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{findPrimaryBuilding(productDetail)?.addressList?.[0].address ?? "-"}</span>
                </div>
              </div>
            </section>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <div>
                  <p className="">유의사항</p>
                </div>
              </div>
              <div className="contents-container__grid-contents ">
                <p className="font14 pre-formatted">{productDetail?.productNotice ? productDetail?.productNotice : "-"}</p>
              </div>
            </section>
          </div>

          <div className="contents-container__wrap-article">
            <BaseSectionTitle title={"고객센터"} />

            {/* VOC 링크 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">문의채널</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span className="font14">{productDetail.vocUrl ?? "-"}</span>
                </div>
              </div>
            </section>
          </div>

          <div className="contents-container__wrap-article">
            <BaseSectionTitle title={"Taap 홈화면"} />

            {/* 테마 색상 */}
            <div className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="">테마색상</p>
              </div>
              <div className="contents-container__grid-contents product-theme">
                <div className={`product-theme ${productDetail.colorName ?? ""} h45 w45`}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="right-area d-flex">
          {isAuthority("PRODUCT", "w") && (
            <BaseButton
              title="수정"
              className="size-large"
              onClick={() => {
                const formPath = `${PagePath.product.form}?id=${productId}&tab=productInfo`;
                navigate(formPath);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductInfoForm;
