import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { UnionServiceType } from "src/api/adminMemo/adminmemo-types";
import { getAvailableReservationTimeAsync, getReservationPolicyClosedPeriodAsync, getUnitReservationTimeAsync } from "src/api/product/product-api";
import { ProductModel } from "src/api/product/product-types";
import { BaseButton } from "src/components";
import ImagesDetail from "src/components/ImagesDetail";
import useGetReservationPolicyMode from "src/hooks/product/useGetReservationPolicyMode";
import { SpaceTypeLabel, SpaceTypeT } from "src/pages/product/product-types";
import { useModal } from "src/recoil/modalState/hook";
import { globalPartnerState } from "src/recoil/partners/atom";
import { axiosInstance } from "src/utils";
import { delete_cols, desk_cols, duplicate_cols, edit_cols, meeting_cols, refresh_cols } from "../columns/SpaceColumns";
import { makeUsedSpaceTableData } from "../libs";
import SpaceAddModal from "../modal/SpaceAddModal";
import ReservationPolicyView from "./ReservationPolicyView";
import SpaceBaseTable from "./SpaceBaseTable";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { useLocation } from "react-router-dom";
import { UnionPartnerPrivilegeCode } from "src/types/partner";

type Props = {
  product: ProductModel;
  spaceType: SpaceTypeT;
  serviceId?: string;

  serviceType?: UnionServiceType;
};
interface AuthortyPage {
  [key: string]: string;
  // 다른 속성들...
}

export const CURRENT_PAGE: AuthortyPage = {
  product: "PRODUCT",
  contract: "CONTRACT",
};

/**
 * 공용공간
 * 토글 활성화시
 */
const SpaceContents = ({ product, spaceType, serviceId, serviceType }: Props) => {
  const { setAbstractModalZ1 } = useModal();
  const { isAuthority } = usePartnerAuthority();
  const partner = useRecoilValue(globalPartnerState);

  const { pathname } = useLocation();

  const PAGE_NAME: any = useMemo(() => {
    const splitPath = pathname.split("/");
    const currentPageName = splitPath[5];
    return CURRENT_PAGE[currentPageName];
  }, [pathname]);

  /** 파트너의 공용공간 예약의 후불정산 기능 활성 여부  */
  const isPostPaymentReservation = partner?.features?.filter((PaPartner) => PaPartner.name === "FEATURE_RESERVATION_POST_PAYMENT")?.[0]?.active;

  //isPostPaymentReservation 가 false 면 duplicate_cols컬럼에서 isAllowedOverReservation 제거
  const newDuplicate_cols = isPostPaymentReservation ? duplicate_cols : duplicate_cols.filter((data) => data.accessor !== "isAllowedOverReservation");
  const newDelete_cols = isAuthority(PAGE_NAME, "d") ? delete_cols : [];
  // const newEdit_cols = isAuthority(PAGE_NAME, "w") ? edit_cols : [];

  /** 공간별 이용한도, 테이블 컬럼 세팅,  */
  const tableColumns = {
    MEETING_ROOM: [
      ...meeting_cols,
      ...newDuplicate_cols, //
      // ...newEdit_cols,
      ...edit_cols,
      ...newDelete_cols,
    ],
    DESK: [
      ...desk_cols,
      ...newDuplicate_cols, //
      // ...newEdit_cols,
      ...edit_cols,
      ...newDelete_cols,
    ],
    REFRESH_ROOM: [
      ...refresh_cols,
      ...newDuplicate_cols, //
      // ...newEdit_cols,
      ...edit_cols,
      ...newDelete_cols,
    ],
  };

  //예약 가능 기간
  const { data: availableReservationTimeList } = useQuery({
    queryKey: ["getAvailableReservationTimeAsync", serviceType, serviceId, spaceType],
    queryFn: () =>
      getAvailableReservationTimeAsync(axiosInstance, {
        serviceId,
        serviceType,
        commonFacilityType: spaceType,
        isDefault: false,
      }).then((res) => res?.data?.data?.content),
  });

  //예약 가능 단위
  const { data: unitReservationTimeList } = useQuery({
    queryKey: ["getUnitReservationTimeAsync", serviceType, serviceId, spaceType],
    queryFn: () =>
      getUnitReservationTimeAsync(axiosInstance, {
        serviceId,
        serviceType,
        commonFacilityType: spaceType,
        isDefault: false,
      }).then((res) => res?.data?.data?.content),
  });

  // 휴무
  const { data: reservationPolicyClosedPeriodList } = useQuery({
    queryKey: ["getReservationPolicyClosedPeriodAsync", serviceType, serviceId, spaceType],
    queryFn: () =>
      getReservationPolicyClosedPeriodAsync(axiosInstance, {
        serviceId,
        serviceType,
        commonFacilityType: spaceType,
        isDefault: false,
      }).then((res) => res?.data?.data?.content),
  });

  const { data: reservationPolicyModeList } = useGetReservationPolicyMode({
    reservationPolicyModeListParams: {
      serviceType,
      serviceId,
      commonFacilityType: spaceType,

      buildingCommonFacilityId: makeUsedSpaceTableData(product, spaceType)
        ?.map((data) => data?.id)
        .join(","),
    },
  });

  if (!(availableReservationTimeList && unitReservationTimeList && reservationPolicyClosedPeriodList && reservationPolicyModeList)) {
    return null;
  }

  return (
    <>
      <ReservationPolicyView
        spaceType={spaceType}
        serviceType={serviceType!}
        serviceId={serviceId!}
        isEditable={isAuthority("PRODUCT", "w")}
      ></ReservationPolicyView>

      {/* 공간 추가 버튼 */}
      <div className="flex-center-end mt20">
        {isAuthority(PAGE_NAME, "w") && (
          <BaseButton
            title={"+ " + SpaceTypeLabel[spaceType] + "추가"}
            className="ml10"
            onClick={() => {
              setAbstractModalZ1({
                size: "xlarge",
                isOpen: true,
                children: (
                  <SpaceAddModal
                    addedSpaceIds={makeUsedSpaceTableData(product, spaceType)?.map((data) => data?.id) as any as string[]}
                    spaceType={spaceType}
                  />
                ),
              });
            }}
          />
        )}
      </div>

      {/* 추가된 공간 테이블 */}
      <div className="my20">
        <SpaceBaseTable
          data={makeUsedSpaceTableData(product, spaceType)}
          columns={tableColumns[spaceType]}
          availableReservationTimeList={availableReservationTimeList}
          unitReservationTimeList={unitReservationTimeList}
          reservationPolicyClosedPeriodList={reservationPolicyClosedPeriodList}
          reservationPolicyModeList={reservationPolicyModeList}
          serviceType={serviceType}
          serviceId={product?.id}
        />
      </div>
      {spaceType === "DESK" && (
        <div>
          <div className="contents-container__grid mt20">
            <div className="contents-container__grid-index">
              <p className="">배치도</p>
            </div>
          </div>
          <div className="mt20">
            <ImagesDetail
              images={product.buildingList?.map((building) => building?.buildingCommonFacility?.deskSpace?.mediaList ?? []).flat() ?? []}
              isUsedDescription={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SpaceContents;
